.dataComponent {
    font-family: "Lato";
    color: #ffffff;
    margin-bottom: 28px;
}

.dataComponentTitle {
    font-size: 20pt;
    margin-bottom: 3px;
}

.dataComponentValue {
    font-size: 30pt;
    
}