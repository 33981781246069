body {
  margin: 0;
}

.mainContainer {
  height: 1080px;
  width: 830px;

  background-color: #343434;
}

.headerContainer {
  position: absolute;
  z-index: 2;
}

.backgroundLayerContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.set1Controls {
  position: absolute;
  top: 130px;
  left: 35px;
}

.datacomponentContainer {
  margin-bottom: 1%;
}

.set2Controls {
  position: absolute;
  top: 555px;
  left: 35px;
}